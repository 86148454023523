export default class ProfileBox {
    constructor(){
       
        if(jQuery('.profileBox .card .card-header').length>0){
        
            jQuery('.profileBox .card .card-header').each(function(){
    
            jQuery(this).addClass('contract');
            jQuery(this).find('.short-paragraph').each(function(){
                
                var par = jQuery(this).text().trim();
              
                let lastChar =par.charAt(par.length-1);
                if(lastChar=="."){
                    jQuery(this).find('p').append('<span class="dots">..</span>')
                }else{
                    jQuery(this).find('p').append('<span class="dots">...</span>')
                }
            })
            jQuery(this).find('.long-paragraph').hide();
           
            jQuery(this).find('.tgl__link').on('click',function(){
    
                // Find parent element with card classname
                var card = jQuery(this).parents().eq(3);
                
                if(card.hasClass('contract')){
                   
                    card.addClass('expand');
                    card.removeClass('contract');
                    jQuery(this).find('i').removeClass('fa-plus');
                    jQuery(this).find('i').addClass('fa-minus');
                    card.find('.short-paragraph').hide();
                    let row = jQuery(this).parents().eq(2);
                    row.find('.long-paragraph').show();
                    row.find('.short-paragraph .dots').hide();
                }else{
                    card.removeClass('expand');
                    card.addClass('contract');
                    card.find('.short-paragraph').show();
                    jQuery(this).find('i').removeClass('fa-minus');
                    jQuery(this).find('i').addClass('fa-plus');
                    let row = jQuery(this).parents().eq(2);
                    row.find('.long-paragraph').hide();
                    row.find('.short-paragraph .dots').show();
                }
            })
    
        })
        }
    }
    

 
}