export default class Testimonials {
    constructor() {
        try {

            this._eachBoxWidth = null;
            this._eachmargin = null;
            this._totalBoxWidth = null;
            this._diff = null;
            this.rangeslider();
            this.setTesimonialVal();
            this.gestureWidth = null;

            jQuery(window).on('resize', this.setTesimonialVal());
            var testimonialsBlock = document.querySelectorAll('.testimonial');


            jQuery('.testimonial .inner_content-testi').on('mouseenter', this.testimonialsEnter);
            jQuery('.testimonial .inner_content-testi').on('click', this.testimonialsClick);


        } catch (error) {
            console.log("Error on constructor :", error);
        }

    }
    isIE() {
        var userAgent = navigator.userAgent;
        return userAgent.indexOf('MSIE') !== -1 ||
            userAgent.indexOf('Trident') !== -1;
    }
    testimonialsClick() {
        try {
            jQuery(this).addClass('active');
        } catch (error) {
            console.log("Error on testimonialsClick : ", error);
        }
    }
    testimonialsEnter() {
        try {
            jQuery('.testimonial .inner_content-testi').removeClass('active');
        } catch (error) {
            console.log("Error on testimonialsEnter : ", error);
        }
    }
    rangeslider() {
        try {

            jQuery('.testimonial').css('margin-left', '-' + jQuery('.range-slider__input').val() + "px")
        } catch (error) {
            console.log("Error on rangeslider :", error);
        }

    }
    slideTestimonials() {
        try {
            if (jQuery(window).width() > 767) {
                jQuery('.inner_content-testi').removeClass('active');
                jQuery(this).addClass('active');
            }
        } catch (error) {
            console.log("Error on slideTestimonials :", error);
        }
    }
    setTesimonialVal() {
        try {
            jQuery('.client-testimonials-wrapper').css('width', jQuery(window).width());
            this._eachBoxWidth = jQuery('.inner_content-testi').outerWidth();
            this._eachmargin = 14;
            this._totalBoxWidth = jQuery('.inner_content-testi').length * (this._eachBoxWidth + this._eachmargin);
            this._totalBoxWidth += 20;
            this._diff = this._totalBoxWidth - jQuery(window).width();
            jQuery('.range-slider__input').attr('max', this._diff);
            jQuery('.testimonial').css('margin-left', '-' + jQuery('.range-slider__input').val() + "px");

            if (this.isIE()) {
                jQuery('.range-slider__input').on('change', this.rangeslider);
            } else {
                jQuery('.range-slider__input').on('input', this.rangeslider);
            }


            if (jQuery(window).width() > 767) {

                jQuery(document).on('click', '.inner_content-testi', this.slideTestimonials);
            } else {
                jQuery(document).off('click', '.inner_content-testi', this.slideTestimonials);
            }
        } catch (error) {
            console.log("Error on setTesimonialVal :", error);
        }
    }
}