export const ServicesPill = {
    activeTab: "nav-default-tab",
    init: function() {
        try {

            if (jQuery('.service-tabs a.nav-link').length > 0) {
                jQuery(document).on("click", ".service-tabs a.nav-link", ServicesPill.pillLinkClick);
            }

            if (jQuery('.hover-icon').length > 0) {
                jQuery('.hover-icon').on("mouseenter", this.pillLinkMouseEnter);
                jQuery('.hover-icon').on("mouseleave", this.pillLinkMouseLeave);
            }

            ServicesPill.activeEachBlock();
            ServicesPill.stopAllSVG();
            jQuery(window).on("resize", function() {
                ServicesPill.activeEachBlock();
            });

        } catch (error) {
            console.log("Error on init : ", error);
        }
    },
    stopAllSVG: function() {
        try {

            medicalExamSVG_index.stop();
            incidentSVG_index.stop();
            onsiteSVG_index.stop();
            consultingSVG_index.stop();
            leaveSVG_index.stop();
        } catch (error) {
            console.log("Error on stopAllSVG :" + error)
        }
    },
    pillLinkMouseEnter: function() {
        try {

            jQuery(this).parent().find('.hover-icon').addClass('fade-icon');
            jQuery(this).find('.hover-block').fadeIn();
            jQuery(this).removeClass('fade-icon');
        } catch (error) {
            console.log("Error on pillLinkMouseEnter : ", error);
        }
    },
    pillLinkMouseLeave: function() {
        try {
            jQuery(this).parent().find('.hover-icon').removeClass('fade-icon');
            jQuery(this).find('.hover-block').fadeOut();
        } catch (error) {
            console.log("Error on pillLinkMouseLeave : ", error);
        }
    },
    pillLinkClick: function() {
        try {
            if (ServicesPill.activeTab != jQuery(this).attr('id')) {
                ServicesPill.activeTab = jQuery(this).attr('id');
                ServicesPill.stopAllSVG();
                setTimeout(() => {
                    switch (jQuery(this).attr('id')) {
                        case "nav-medical-tab":
                            medicalExamSVG_index.play();
                            break;

                        case "nav-incident-tab":
                            incidentSVG_index.play();
                            break;

                        case "nav-onsite-tab":
                            onsiteSVG_index.play();
                            break;

                        case "nav-consulting-tab":
                            consultingSVG_index.play();
                            break;

                        case "nav-clinical-tab":
                            leaveSVG_index.play();
                            break;
                    }
                }, 100)



            } else {
                ServicesPill.activeTab = "nav-default-tab";
                jQuery(this).removeClass('active');
                jQuery('#nav-tabContent .tab-pane').removeClass('active show');
                jQuery('#nav-tabContent #nav-default').addClass('active');
                setTimeout(() => {
                    jQuery('#nav-tabContent #nav-default').addClass('show');
                }, 80)

            }

            if ($(window).width() < 767) {
                ServicesPill.scrollPage();
            }
        } catch (error) {
            console.log("Error on pillLinkClick : ", error);
        }
    },
    scrollPage: function() {
        console.log("Scroll Page");
        var services = document.querySelector('#nav-tabContent');
        console.log(services.getBoundingClientRect());
        console.log(services)
            // alert(services.offsetTop);
        var topPos;

        if ($(window).width() <= 414) {
            topPos = 1450;
        } else if ($(window).width() > 414 && $(window).width() < 767) {
            topPos = 1520;
        }

        window.scrollTo({ top: topPos, left: 0, behavior: 'smooth' })
    },
    setHeightForHoverBlock: function(ele) {
        try {

            ele.parents().eq(1).css('height', ele.find('.hover-block')[0].getBoundingClientRect().height + 'px');

        } catch (error) {
            console.log("Error on setHeightForHoverBlock : ", error);
        }
    },
    activeEachBlock: function() {
        try {
            if (jQuery(window).width() <= 767 && jQuery('.hover-desc-wrapper .hover-icon').length > 0) {

                jQuery('.hover-desc-wrapper .hover-icon:first-child').addClass('active');

                jQuery('.icons-list-wrapper').each(function() {
                    let firstHoverBlock = jQuery(this).find('.hover-icon:first-child .hover-block')[0].getBoundingClientRect();
                    jQuery(this).css('height', firstHoverBlock.height + "px");
                })

                jQuery('.hover-icon').on("click", function() {
                    ServicesPill.setHeightForHoverBlock(jQuery(this));
                    jQuery(this).parent().find('.hover-icon').removeClass('active');
                    jQuery(this).addClass('active');
                })

            } else {
                jQuery('.hover-desc-wrapper .hover-icon:first-child').removeClass('active');
            }
        } catch (error) {
            console.log("Error on activeEachBlock : ", error);
        }
    }

}