import { ScrollNavOnTab } from './ScrollNavOnTab.js';
import { ServicesPill } from './ServicesPill.js';
import Testimonials from './Testimonials.js';
import About from './About.js';
import ProfileBox from './ProfileBox.js';
import SearchResource from './SearchResource.js'; 
import { polyfill } from 'es6-promise';

polyfill();


var scroll_arrow = {
    templateUrl: 'http://workcare.loc/'
}




jQuery(document).ready(function() {

    $('.image-slider').slick({
        autoplay: true,
        dots: false,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    if (jQuery('.caption-wrap p').length > 0) {
        //jQuery('.caption-wrap p').matchHeight({});
    }


    GreenAudioPlayer.init({
        selector: '.ready-player', // inits Green Audio Player on each audio container that has class "player"
        stopOthersOnPlay: true
    });

    var nav = jQuery('.wc-primary-nav');
    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 95) {
            nav.addClass("fixed-nav");
        } else {
            nav.removeClass("fixed-nav");
        }
    });


    jQuery('.main-header .navbar-toggler').on('click', function() {
        jQuery('.primary-nav').toggleClass('opened-nav');
        jQuery('body').toggleClass('fixed-body');
    })

    jQuery('.menu-overlay').on('click', function() {
        jQuery('.main-header .navbar-toggler').click();
    });

    jQuery('.primary-nav-wrapper ul > .nav-item > i.fa-caret-down').on('click', function() {
        jQuery(this).parent().toggleClass('dropdown-active');
    });

    jQuery('.primary-nav-wrapper ul > .nav-item > ul > .nav-item > i.fa-caret-down').on('click', function() {
        jQuery(this).parent().toggleClass('dropdown-active-third');
    });

    ScrollNavOnTab.init();


    ServicesPill.init();


    new Testimonials();
    new About();
    new ProfileBox();




});

 new SearchResource();
