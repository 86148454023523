export default class About {
    constructor(){
       
        this.about ={
            workcareway:0,
            history:0
        }       
       
        if(jQuery(window).width()>1024){
            console.log("Initialize");
            this.init();
        }

        
    }
    
    init(){
       
        let hash = window.location.href;
        let splithash = hash.split("=");
       
        if(splithash.length>1){
            let hashName = splithash[1];
            console.log(hashName);

           var top;
            switch(hashName){
                case "the_workcare_way":
                top= eval(jQuery('#the_workcare_way_sec')[0].getBoundingClientRect().top-50);
                jQuery('body,html').animate({scrollTop:1391.0},800);
                break;

                case "history":
                top = eval(jQuery('#historysec')[0].getBoundingClientRect().top-150);
                jQuery('body,html').animate({scrollTop:2800},800);
                break;
            }
        }
       
        jQuery('a#history,a#workcareway').on('click',function(){
            console.log("Interlink");
            var me = jQuery(this)[0].id;
            console.log(jQuery('#the_workcare_way_sec'));

            switch(me){
                case "workcareway":
                jQuery('body,html').animate({scrollTop:1391.0},800);
                break;

                case "history":
                console.log("History Page");
                console.log("workcareway ", jQuery('#historysec')[0]);
                jQuery('body,html').animate({scrollTop:2800},800);
               
                break;
            }
        })
        
      
    }
}