export const ScrollNavOnTab = {

    tabName:null,
    tabIndex:null,
    topPos:null, 

    scrollActiveTab : function (){
        try{
            console.log("Tab Index : " + ScrollNavOnTab.tabIndex);
            ScrollNavOnTab.topPos = jQuery("#pillstabs").offset().top - jQuery('header.main-header').height();
            if(ScrollNavOnTab.tabIndex>0){
                jQuery('html, body').scrollTop(ScrollNavOnTab.topPos); 
                ScrollNavOnTab.tabIndex=parseInt(ScrollNavOnTab.tabIndex);
                jQuery('#pillstabs ul li:nth-child('+ScrollNavOnTab.tabIndex+') a').trigger('click');

               }
        }catch(error){
            console.log("Error on scrollActiveTab :", error);
        }
     },
    trackLocation : function (){        
        try{       
            ScrollNavOnTab.tabName = window.location.hash;      
            ScrollNavOnTab.tabIndex = this.tabName.split("=")[1];       
            ScrollNavOnTab.topPos = jQuery("#pillstabs").offset().top - jQuery('header.main-header').height()-142;
        }catch(error){
            console.log("Error on trackLocation :", error);
        }
    },
    triggerTabLink: function(e){
        try{ 
           
            ScrollNavOnTab.tabName = window.location.hash;
            ScrollNavOnTab.tabIndex = e.currentTarget.dataset.index;   
            ScrollNavOnTab.scrollActiveTab();
        }catch(error){
            console.log("Error on triggerTabLink :", error);
        }
    },

    init:function(){
        try{ 
            
            if(jQuery('li#onsiteservices ul li a').length>0){
                var pageName = window.location.pathname.slice(1,window.location.pathname.length);
                jQuery('#onsiteservices ul li a').on('click',ScrollNavOnTab.triggerTabLink);
                       
                if(pageName=="services-onsite-service-clinics.php"){             
                    ScrollNavOnTab.trackLocation();    
                    ScrollNavOnTab.scrollActiveTab();
                }
            
            }
        }catch(error){
            console.log("Error on init :", error);
        }
    }
}
