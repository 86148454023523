const axios = require("axios");
var Promise = require("es6-promise").Promise;

export default class SearchResource {
  constructor() {
    if (document.querySelectorAll("#searchBox input").length > 0) {
      this.intervalSearch = null;
      this.ele().addEventListener("keyup", this.searchingResource);
    }

    $("#modal-box").on("hidden.bs.modal", function (e) {
      SearchResource.prototype.removeModalBody();
    });

    /* Once user click on close Button it need to remove all content of modal-body */
    jQuery(document).on("click", "button.close", () => {
      this.removeModalBody();
    });

    jQuery(document).on("click", "a.resources-post-block", function () {
      var payload = {
        postid: $(this).attr("data-id"),
        media: $(this).attr("data-media"),
      };
      SearchResource.prototype.resourceMedia(payload);
    });
  }
  removeModalBody() {
    if (jQuery("#modal-box").length > 0) {
      if (jQuery("#modal-box .modal-body").length > 0) {
        jQuery("#modal-box .modal-body").remove();
        jQuery("#modal-box").modal("hide");
      }
    }
  }
  showPodcast(queryData) {
    try {
      return `
                <div class="modal-body">
                    <div class="modal-content-inner-wrapp">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="modal-image-content-wrapp">
                                    <img src="${queryData.thumbnail}" class="img-fluid" alt="${queryData.title}">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-9">
                                <div class="modal-content-details-wrapp">
                                    <div class="modal-header">
                                        <h5 class="modal-title">${queryData.title} </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <img src="${scroll_arrow.templateUrl}/assets/img/icons/close.png" class="img-fluid" alt="">
                                        </button>
                                    </div>
                                    <div class="embed-embed-responsive-16by9 audio-wrapp">
                                        <script src="https://fast.wistia.com/embed/medias/${queryData.src}.jsonp" async></script>
                                        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
                                        <div class="wistia_embed wistia_async_${queryData.src} seo=false" style="width:100%;height:218px;position:relative">&nbsp;</div>
                                    </div>
                                    <p>${queryData.content} </p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
    } catch (error) {
      console.log("Error on showPodcast :", error);
    }
  }
  showContentVideo(queryData) {
    try {
      return ` 
        <div class="modal-body">
            <div class="modal-content-inner-wrapp">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-7">
                        <div class="modal-video-content-wrapp">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="${queryData.src}"></iframe> 
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5">
                        <div class="modal-content-details-wrapp">
                            <div class="modal-header">
                                <h5 class="modal-title">${queryData.title}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <img src="${scroll_arrow.templateUrl}/assets/img/icons/close.png" class="img-fluid" alt="">
                                </button>
                            </div>
                            <p>${queryData.content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
    } catch (error) {
      console.log("Error on showContentVideo :", error);
    }
  }
  showEmptyContentVideo(queryData) {
    try {
      return `
            <div class="modal-body video_block">
                <div class="modal-content-inner-wrapp">       
                    <button type="button" class="close for_video" data-dismiss="modal" aria-label="Close">
                        <img src="${scroll_arrow.templateUrl}/assets/img/icons/close.png" class="img-fluid" alt="">
                    </button>       
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 ">
                            <div class="modal-video-content-wrapp">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item"
                                        src="${queryData.src}"></iframe>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                 </div>
            </div>`;
    } catch (error) {
      console.log("Error on showEmptyContentVideo :", error);
    }
  }
  resourceMedia(payload) {
    try {
      axios.interceptors.request.use(
        (config) => {
          document.querySelector("#loading-news").style.visibility = "visible";
          return config;
        },
        (error) => {
          console.log(error);
        }
      );

      var htmlFormData = new FormData();
      htmlFormData.append("action", "resources_media");
      htmlFormData.append("id", payload.postid);
      htmlFormData.append("media", payload.media);

      axios
        .post(ajaxurl, htmlFormData)
        .then(function (response) {
          try {
            document.querySelector("#loading-news").style.visibility = "hidden";
            console.log(response.data.mediaresult);
            var mediaresult = response.data.mediaresult[0];

            var item = mediaresult.replace(/(\r\n|\n|\r\s)/gm, "");
            item = item.replace(/\s+/g, " ");
            console.log("Item ", item);
            var queryData = JSON.parse(item);

            // Show Pocast Modal Box
            if (payload.media == "podcast") {
              jQuery("#modal-box .modal-content").html(
                `${SearchResource.prototype.showPodcast(queryData)}`
              );
              jQuery("#modal-box").modal();
            }

            // Show Pocast Video Modal Box
            if (payload.media == "video") {
              // If Video have content then  showContentVideo otherwise  showEmptyContentVideo
              if (queryData.content.length == 0) {
                // Showing EmptyContentVideo Modal Box
                jQuery("#modal-box .modal-content").html(
                  `${SearchResource.prototype.showEmptyContentVideo(queryData)}`
                );
                jQuery("#modal-box").modal();
              } else {
                // Showing ContentVideo Modal Box
                jQuery("#modal-box .modal-content").html(
                  `${SearchResource.prototype.showContentVideo(queryData)}`
                );
                jQuery("#modal-box").modal();
              }
            }
          } catch (error) {
            console.log("Error while success outpt on ajax called ", error);
          }
        })
        .catch(function (error) {
          // handle error
          console.log("Error on Axios Catch :", error);
        })
        .then(function () {
          // console.log("Completed");
          document.querySelector("#loading-news").style.visibility = "hidden";
        });
    } catch (error) {
      console.log("Error on resourceMedia", error);
    }
  }

  ele() {
    try {
      return document.querySelector("#searchBox input");
    } catch (error) {
      console.log("Error on ele :", error);
    }
  }
  contWraper() {
    try {
      return document.querySelector(".tab-content-wrapp");
    } catch (error) {
      console.log("Error on contWraper :", error);
    }
  }

  cloneEle() {
    try {
      return document.querySelector(".tab-content-wrapp").cloneNode(true);
    } catch (error) {
      console.log("Error on cloneEle :", error);
    }
  }
  searchQuery() {
    try {
      var htmlFormData = new FormData();
      htmlFormData.append("action", "resources_search");
      htmlFormData.append("keyword", this.ele().value);
      htmlFormData.append("template", this.ele().dataset.template);
      htmlFormData.append("target", this.ele().dataset.target);

      axios.interceptors.request.use(
        (config) => {
          document.querySelector("#loading-news").style.visibility = "visible";
          return config;
        },
        (error) => {
          // handle the error
          console.log(error);
        }
      );

      axios
        .post(ajaxurl, htmlFormData)
        .then(function (response) {
          if (response.data.searchresult != null) {
            if (response.data.searchresult.length > 0) {
              switch (SearchResource.prototype.ele().dataset.layout) {
                case "grid-pdf":
                  SearchResource.prototype.gridPDF(response.data);
                  break;

                case "list-pdf":
                  SearchResource.prototype.listPDF(response.data);
                  break;

                case "list-news":
                  SearchResource.prototype.listNews(response.data);
                  break;

                case "grid-media":
                  SearchResource.prototype.gridMedia(response.data);
                  break;
              }
            } else {
              console.log("Response else");
              SearchResource.prototype.contWraper().innerHTML = `<h3>No any data found on "${
                SearchResource.prototype.ele().dataset.target
              }" with the search keyword of "<em>${
                SearchResource.prototype.ele().value
              }</em>"</h3>`;
            }
          } else {
            window.location.reload();
            return false;
          }
        })
        .catch(function (error) {
          // handle error
          console.log("Error on Axios Catch :", error);
        })
        .then(function () {
          // console.log("Completed");
          document.querySelector("#loading-news").style.visibility = "hidden";
        });
    } catch (error) {
      console.log("Error on searchQuery 1.0", error);
    }
    /*
      axios
        .post(ajaxurl, htmlFormData)
        .then(function (response) {
          if (response.data.searchresult != null) {
            if (response.data.searchresult.length > 0) {
              switch (SearchResource.prototype.ele().dataset.layout) {
                case "grid-pdf":
                  SearchResource.prototype.gridPDF(response.data);
                  break;

                case "list-pdf":
                  SearchResource.prototype.listPDF(response.data);
                  break;

                case "list-news":
                  SearchResource.prototype.listNews(response.data);
                  break;

                case "grid-media":
                  SearchResource.prototype.gridMedia(response.data);
                  break;
              }
            } else {
              console.log("Response else");
              SearchResource.prototype.contWraper().innerHTML = `<h3>No any data found on "${
                SearchResource.prototype.ele().dataset.target
              }" with the search keyword of "<em>${
                SearchResource.prototype.ele().value
              }</em>"</h3>`;
            }
          } else {
            window.location.reload();
            return false;
          }
        })
        .catch(function (error) {
          // handle error
          console.log("Error on Axios Catch :", error);
        })
        .then(function () {
          // console.log("Completed");
          document.querySelector("#loading-news").style.visibility = "hidden";
        });
        */
  }
  gridMedia(data) {
    try {
      console.log("gridMedia", data);
      SearchResource.prototype.contWraper().innerHTML = `<div class="row">${data.searchresult
        .map((item) => {
          item = JSON.parse(item);
          return ` 
                <div class="col-sm-12 col-md-6 col-lg-3 gutters-13">
                    <a href="#" class="resources-post-block" data-id="${
                      item.id
                    }" data-media="${
            item.podcast.length > 0 ? `podcast` : `video`
          }">
                        <div class="resources-thumbnail">
                            <div class="banner-overlay" style="background-color: rgba(78, 78, 78, 0.5);">
                            </div>
                            <img src="${item.thumbnail}" alt="${
            item.title
          }" class="img-fluid">
                            <strong>${scroll_arrow.templateUrl}</strong>
                            <div class="icon-play">
                                <img src="${
                                  item.podcast.length > 0
                                    ? `${scroll_arrow.templateUrl}/assets/img/icons/workcare-sound-icon.svg`
                                    : `${scroll_arrow.templateUrl}/assets/img/icons/workcare-play-icon.svg`
                                }" alt="" class="img-fluid">
                            </div>
                        </div>
                                                <span>Podcast</span>
                                <h5>${item.title}</h5>
                    </a>
                </div>`;
        })
        .join("")}</div>`;
    } catch (error) {
      console.log("Error on gridMedia :", error);
    }
  }
  gridPDF(data) {
    try {
      SearchResource.prototype.contWraper().innerHTML = `<div class="row">${data.searchresult
        .map((item) => {
          item = JSON.parse(item);
          return ` 
                <div class="col-sm-12 col-md-6 col-lg-3 gutters-13">
                    <a href="${
                      item.pdf
                    }" target="_blank" class="resources-post-block">
                        <div class="resources-thumbnail">
                      
                            <img src="${item.thumbnail}" alt="${
            item.title
          }" class="img-fluid">
                        </div>
                        ${
                          SearchResource.prototype.ele().dataset.target ==
                          "SearchResource.prototype.ele()"
                            ? ` <span>PDF</span>`
                            : ``
                        }
                       
                        <h5>${item.title}
                        
                        </h5>
                       
                    </a>
                </div>`;
        })
        .join("")}</div>`;
    } catch (error) {
      console.log("Error in template1 :", error);
    }
  }

  listPDF(data) {
    try {
      SearchResource.prototype.contWraper().innerHTML = `<div class="periodicals-content-main-wrapp">${data.searchresult
        .map((item) => {
          item = JSON.parse(item);

          return ` <div class="row">
                <div class="col-sm-12 col-md-6 gutters-5">
                    <a href="${item.pdf}" target="_blank" class="periodicals-image-wrapp">                       
                        <img src="${item.thumbnail}" alt="${item.title}" class="img-fluid">
                    </a>
                </div>
                <div class="col-sm-12 col-md-6 col-md-6 pr-0">
                    <div class="periodicals-image-details">
                        <a href="${item.pdf}" target="_blank">${item.pdf_title}</a>
                        <h5>${item.title}</h5>
                        <p>${item.content} […]</p>
                    </div>
                </div>
                </div>`;
        })
        .join("")}</div>`;
    } catch (error) {
      console.log("Error in template1 :", error);
    }
  }

  listNews(data) {
    try {
      SearchResource.prototype.contWraper().innerHTML = `<div>${data.searchresult
        .map((item) => {
          item = item.replace(/(\r\n|\n|\r\s)/gm, "");
          item = item.replace(/\s+/g, " ");

          item.trim();
          console.log(item);
          item = JSON.parse(item);

          return ` 
                    <div class="news-block-section">
                        <div class="media">
                            <div class="clander-wrapp align-self-center">
                                <div class="clander-image" style="background-image: url('http://workcare.loc/wp-content/themes/workcaretwentytwenty/assets/img/icons/workcare-calendar.svg');">
                                    <span>${item.day}</span>
                                </div>
                                <p> ${item.month}<br>${item.year} </p>
                            </div>
                            <div class="media-body">
                                <h5 class="mt-0">${item.title}</h5>
                                <p>${item.excerpt}</p>
                                <a  class="navigate-single readmore" data-postid="${item.id}">Read More</a>
                            </div>
                        </div>
                    </div>
                    
                    `;
        })
        .join("")}</div> `;

      SearchResource.prototype.crawlreadmore();
    } catch (error) {
      console.log("Error in template1 :", error);
    }
  }
  crawlreadmore() {
    try {
      console.log("....crawlreadmore");
      var allLinks = document.querySelectorAll(
        ".news-block-section a.readmore"
      );
      console.log(allLinks);
      let i = 0;
      while (i < allLinks.length) {
        allLinks[i].addEventListener("click", this.showDetailNews);
        i++;
      }
    } catch (error) {
      console.log("Error on crawlreadmore :", error);
    }
  }
  showDetailNews() {
    try {
      console.log("Show Detail News");
    } catch (error) {
      console.log("Error on showDetailNews :", error);
    }
  }
  searchingResource(e) {
    try {
      clearTimeout(SearchResource.intervalSearch);
      SearchResource.intervalSearch = setTimeout(() => {
        SearchResource.prototype.searchQuery(e);
      }, 1000);
    } catch (error) {
      console.log("Error on searchingResource :", error);
    }
  }
}

SearchResource.ele = document.querySelector("#searchBox input");
